.content {
    position: relative;
    grid-area: 1 / 1;
    min-height: 11.25rem;
    padding: theme('spacing.200') theme('spacing.200') theme('spacing.500');
    color: theme('colors.neutrals.white');

    @media (min-width: theme('screens.md')) {
        grid-area: auto;
        min-height: auto;
        padding: theme('spacing.200') theme('spacing.100') theme('spacing.200') theme('spacing.200');
        color: theme('colors.content.foundation');
    }

    @media (min-width: theme('screens.lg')) {
        @apply col-span-7;
    }
}

.image {
    position: relative;
    grid-area: 1 / 1;
    border-bottom-left-radius: 7rem;
    overflow: hidden;

    @media (min-width: theme('screens.md')) {
        grid-area: 1 / 2;
        border-bottom-left-radius: 11rem;
    }

    @media (min-width: theme('screens.lg')) {
        grid-area: 1 / 8 / 3 / 13;
        height: 110%;
    }

    & img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: theme('colors.layers.soft');
        opacity: 0.75;
    }
}

.noImage:after {
    opacity: 1;
}

.button {
    @apply -mt-300;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0 theme('spacing.200') theme('spacing.200');
    text-align: center;

    @media (min-width: theme('screens.md')) {
        grid-area: 1 / 2;
        margin-top: 0;
        padding: theme('spacing.200') theme('spacing.200') theme('spacing.300');
    }

    @media (min-width: theme('screens.lg')) {
        grid-area: 1 / 8 / 3 / 13;
        justify-content: center;
        padding: theme('spacing.200');
    }

    & a {
        max-width: 17.5rem;
        width: 100%;
    }
}
