.Hero {
    @apply before-bg;

    padding-top: theme('rhythm.medium');
    padding-bottom: theme('rhythm.medium');
    border-radius: 0 0 theme('borderRadius.200') theme('borderRadius.200');
    background-color: theme('colors.layers.soft');
    --before-bg-color: theme('colors.layers.soft');
    color: theme('colors.neutrals.white');

    @media (min-width: theme('screens.lg')) {
        padding-top: theme('rhythm.large');
        padding-bottom: theme('rhythm.large');
    }

    @media (min-width: theme('screens.lg')) {
        &:before {
            content: none;
        }
    }
}

.HeroInner {
    @apply container-gutters-small md:max-lg:container-gutters-medium;

    display: flex;
    flex-direction: row-reverse;
    gap: theme('spacing.200');
}

.HeroImageWrapper {
    display: none;

    @media (min-width: theme('screens.md')) {
        position: relative;
        flex-shrink: 0;
        display: block;
    }

    @media (min-width: theme('screens.lg')) {
        @apply -mr-100;
    }
}

.HeroSearchWrapper {
    margin-top: theme('spacing.200');

    @media (min-width: theme('screens.md')) {
        position: relative;
        width: calc(100% + 6rem);
    }

    @media (min-width: theme('screens.lg')) {
        width: calc(100% + 5rem);
    }
}

.image {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 24px 24px 100% 100%;
}
