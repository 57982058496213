.sign_up__item {
    @apply space-y-200 w-full max-w-md;  
}

.sign_up__text_input {
    margin-bottom: 4px;

    label {

        @apply type-heading type-090 mb-0 leading-none;
    }
}

.form__section {
    @apply relative;
    border-top: theme('border.light');
    padding-top: theme('rhythm.medium');
    margin-top: theme('rhythm.medium');
}

.submissionButton {
    @apply block ml-auto w-full mt-rhythm-medium sm:mt-rhythm-large sm:w-auto;
}