.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background-color: theme('colors.layers.soft');
    opacity: 0.75;
    z-index: theme('zIndex.flyout');
    transition: theme('transitionDuration.fast') opacity theme('transitionTimingFunction.default');
}
