.card {
    display: flex;
    flex-direction: column;
    gap: theme('spacing.100');
    justify-content: space-between;
    transition: box-shadow 350ms ease-out;

    &:hover {
        box-shadow: theme('treatmentCards.default.hover.shadow');
    }
}
