.reel {
    @media (min-width: 769px) {
        display: grid;
    }

    & > * {
        width: 75%;

        @media (min-width: theme('screens.sm')) {
            width: 40%;
        }

        @media (min-width: 769px) {
            width: auto;
        }
    }
}
