.wrapper {
    width: 100%;
    max-width: 20rem;
    position: sticky;
    top: 0;
    flex-shrink: 0;
    flex-grow: 1;
    overflow: auto;
    max-height: 100vh;
    display: none;
    border-right: 1px solid theme('colors.decorators.subtle');
    background-color: theme('backgroundColor.layers.foreground');

    @media (min-width: 960px) {
        display: block;
    }
}

.nav {
    background-color: theme('sidebarNavigation.background');
    width: 100%;
}

.navBottomPadding {
    padding-bottom: 5.625rem;
}

.list {
    > li {
        @apply p-050;
    }

    > li + li {
        border-top: 1px solid theme('colors.decorators.subtle');
    }
}

.link {
    padding: theme('spacing.100');
    display: flex;
    align-items: center;
    gap: theme('sidebarNavigationItem.spacing');
    width: 100%;
    background-color: theme('sidebarNavigationItem.default.background');
    border-radius: theme('sidebarNavigationItem.borderRadius');
    color: theme('sidebarNavigationItem.default.textColor');
    box-sizing: border-box;
    fill: theme('sidebarNavigationItem.default.iconColor');
    font-weight: theme('fontWeight.700');

    /** TODO: pull in transition tokens to tailwind, add a default transition here */
}

a.link,
button.link {

    &:focus,
    &:hover {
        background-color: theme('sidebarNavigationItem.hover.background');
        color: theme('sidebarNavigationItem.hover.textColor');
        fill: theme('sidebarNavigationItem.hover.iconColor');
    }
}

.linkActive {
    color: theme('sidebarNavigationItem.active.textColor');
    fill: theme('sidebarNavigationItem.active.iconColor');
    font-weight: theme('fontWeight.800');
}

.submenu {
    padding-left: 2.25rem;
    padding-bottom: theme('sidebarNavigationItem.padding');
}

.sublink {
    display: block;
    padding: theme('spacing.050') 0;
    color: theme('colors.content.quiet');
    font-size: theme('fontSize.080');

    &:focus,
    &:hover {
        color: theme('colors.content.foundation');
    }
}

.sublinkActive {
    color: theme('colors.content.foundation');
}

.signIn {
    position: fixed;
    bottom: theme('sidebarNavigationItem.padding');
    left: 50%;
    width: calc(100% - (theme('sidebarNavigationItem.padding') * 2));
    border-top: none;
    text-align: center;
    transform: translateX(-50%);
}

