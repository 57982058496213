.card {

    @apply  flex
        flex-col
        w-full
        p-0;
}

.cardBody {

    @apply  grid
        grid-cols-2
        w-full
        p-100
        gap-y-150;

    @container (min-width: 768px) {

        @apply  grid-cols-3
            gap-y-150;

    }

    @container (min-width: 1152px) {

        @apply  grid-cols-3
            gap-x-300;

    }
}

.header {

    @apply  flex
        flex-row
        justify-between
        items-center
        w-full
        p-100
        md:py-150;

    border-bottom: theme('card.bordered.border');

}

.headerContent {

    @apply  flex
        flex-row
        justify-between
        w-full;

    @container (min-width: 768px) {

        @apply  justify-start
            gap-x-100;

    }


}

.viewDetails {
    @apply whitespace-nowrap;

    display: none;

    @container (min-width: 768px) {
        @apply block;
    }

    &.footer {
        @apply
            flex
            justify-end
            px-100
            pb-100;


        @container (min-width: 768px) {
            display: none;
        }
    }
}

.issue {

    @apply  flex
        flex-row
        items-center
        justify-between
        w-full
        p-100;

    border-bottom: theme('card.bordered.border');
}

.error {

    @apply  flex
        flex-row
        items-center
        justify-between
        gap-x-050;


}

.orderPlaced {

    @apply  order-1;

}

.estimatedDelivery {

    @apply  order-2;

    @container (min-width: 1152px) {

        @apply  order-3
            col-span-2;

    }

}

.deliveringTo {

    @apply  order-3
        col-span-2;

    @container (min-width: 768px) {

        @apply  col-span-1;

    }

    @container (min-width: 1152px) {

        @apply  order-2;

    }

}

.treatmentList {

    @apply  flex
        flex-col
        order-4
        col-span-2
        gap-y-100;

    @container (min-width: 1152px) {

        @apply  order-2
            col-span-1;

    }
}