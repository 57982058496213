.table {
    width: 100%;
    min-width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    thead {
        color: theme('colors.neutrals.white');
        text-align: left;

        th {
            min-width: 12.5rem;
            padding: theme('table.header.padding.large');
            background: theme('table.header.background');
            overflow: hidden;

            &:first-child {
                border-top-left-radius: theme('borderRadius.100');
            }

            &:last-child {
                border-top-right-radius: theme('borderRadius.100');
            }
        }
    }

    tbody {
        tr {
            td {
                padding: theme('table.cell.padding.large');
                border-left: theme('table.cell.border');
                border-bottom: theme('table.cell.border');

                &:last-child {
                    border-right: theme('table.cell.border');
                }
            }

            &:last-child {
                td {
                    &:first-child {
                        border-bottom-left-radius: theme('borderRadius.100');
                    }

                    &:last-child {
                        border-bottom-right-radius: theme('borderRadius.100');
                    }
                }
            }
        }
    }
}
