.spacing {
    margin-top: theme('spacing.rhythm-medium');

    @media (min-width: theme('screens.lg')) {
        margin-top: theme('spacing.rhythm-large');
    }

    &:last-child {
        margin-bottom: theme('spacing.rhythm-medium');

        @media (min-width: theme('screens.lg')) {
            margin-bottom: theme('spacing.rhythm-large');
        }
    }
}

.container {
    padding: 0 theme('container.gutters.small');
    margin-left: auto;
    margin-right: auto;

    @media (min-width: theme('screens.md')) {
        padding: 0 theme('container.gutters.medium');
    }
}

.offsetBg {
    position: relative;

    * {
        position: relative;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
        height: 50%;
    }
}

.offsetBgBottom::before {
    bottom: 0;
    height: 50%;
}

.offsetBgWhite::before {
    @apply bg-layers-foreground;
}

.mainContent {
    @apply space-y-rhythm-xsmall;

    & > [data-block=image] {
        & figure,
        & img {
            width: 100%;
        }
    }

    & > [data-block=consultation-cta],
    & > [data-block=treatment-cta],
    & > [data-block=selling-points],
    & > [data-block=popular-brands],
    & > [data-block=featured-faqs],
    & > [data-block=quote],
    & > [data-block=treatment-condition-card] {
        padding-top: theme('spacing.rhythm-small');
        padding-bottom: theme('spacing.rhythm-small');
    }

    & > * {
        max-width: theme('container.sizes.small');
        padding-left: theme('container.gutters.small');
        padding-right: theme('container.gutters.small');
        margin-left: auto;
        margin-right: auto;

        @media (min-width: theme('screens.md')) {
            padding-left: theme('container.gutters.medium');
            padding-right: theme('container.gutters.medium');
        }
    }

    & > [data-block=consultation-cta],
    & > [data-block=treatment-cta],
    & > [data-block=image][data-full-width=true] {
        max-width: theme('container.sizes.medium');

        @media (min-width: theme('screens.lg')) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    & > [data-block=treatment-condition-card],
    & > [data-block=popular-brands],
    & > [data-block=selling-points] {
        max-width: theme('container.sizes.large');

        @media (min-width: theme('screens.lg')) {
            padding-left: theme('container.gutters.medium');
            padding-right: theme('container.gutters.medium');
        }
    }
}
